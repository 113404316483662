import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function YoutubeList() {
  const [formData, setFormData] = useState({
    min_subscribers: 100000,
    region_code: "KR",
    max_pages: 1,
    query: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleRun = () => {
    setLoading(true);
    const url = `https://finereview.kro.kr/youtube/process_data`;
    const data = {
      min_subscribers: formData.min_subscribers,
      region_code: formData.region_code,
      max_pages: formData.max_pages,
      query: formData.query,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.blob(); // 파일 데이터를 Blob 형태로 받음
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // Blob URL 생성
        const a = document.createElement("a");
        a.href = url;
        a.download = "filename.xlsx"; // 다운로드 파일 이름 설정
        document.body.appendChild(a);
        a.click(); // 다운로드 트리거
        a.remove();
        window.URL.revokeObjectURL(url); // URL 해제
      })
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error while downloading the file:", err);
      });
  };

  return (
    <div>
      {loading && (
        <div
          style={{
            zIndex: "100",
            position: "absolute",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            right: "50%",
            transform: "translateX(50%)",
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <h5>유튜버 리스트 다운로드 </h5>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <label style={{ flex: "1", fontWeight: "bold" }}>최소 구독자</label>
          <input
            type="text"
            placeholder="최소 구독자"
            value={formData.min_subscribers}
            onChange={(e) =>
              handleInputChange("min_subscribers", e.target.value)
            }
            style={{
              flex: "2",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ddd",
            }}
          />
          <label style={{ flex: "1", fontWeight: "bold" }}>지역 코드</label>
          <input
            type="text"
            placeholder="ex. KR / JP"
            value={formData.region_code}
            onChange={(e) => handleInputChange("region_code", e.target.value)}
            style={{
              flex: "2",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ddd",
            }}
          />
          <label style={{ flex: "1", fontWeight: "bold" }}>키워드</label>
          <input
            type="text"
            placeholder="키워드"
            value={formData.query}
            onChange={(e) => handleInputChange("query", e.target.value)}
            style={{
              flex: "2",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #ddd",
            }}
          />
          <button
            onClick={handleRun}
            style={{
              backgroundColor: "#3182F7",
              color: "white",
              padding: "10px 20px",
              width: "fit-content",
              border: "none",
              borderRadius: "4px",
              marginTop: "40px",
            }}
          >
            실행
          </button>
        </div>
      </div>
    </div>
  );
}
