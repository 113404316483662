import { useState } from "react";
import YoutubeList from "./youtubeList";

const TAB_LIST = [{ id: "유튜브 리스트", label: "유튜브 리스트" }];

const Creator = () => {
  const [activeTab, setActiveTab] = useState("유튜브 리스트");

  const renderTabContent = () => {
    switch (activeTab) {
      case "유튜브 리스트":
        return <YoutubeList />;
      default:
        return <YoutubeList />;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "200px",
          borderRadius: "6px",
          border: "1px solid #ddd",
          height: "fit-content",
        }}
      >
        <h4
          style={{
            margin: 0,
            padding: "12px 16px",
            borderBottom: "1px solid #ddd",
            fontSize: "16px",
            color: "#3182F7",
            fontWeight: "bold",
          }}
        >
          크리에이터
        </h4>

        <div>
          {TAB_LIST.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              style={{
                padding: "10px 16px",
                cursor: "pointer",
                backgroundColor:
                  activeTab === tab.id ? "#3182F7" : "transparent",
                color: activeTab === tab.id ? "white" : "#333",
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <div style={{ padding: "15px", flex: 1 }}>{renderTabContent()}</div>
    </div>
  );
};

export default Creator;
