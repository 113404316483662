import { useState, useEffect } from "react";
import {
  updateUser,
  getRequestedVerificationUsers,
  verifyFineReviewUser,
  rejectFineReviewUser,
} from "../../../api/user";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Spinner from "react-bootstrap/Spinner";

export default function VerifyChannel() {
  const [combinedUsers, setCombinedUsers] = useState([]);
  const [request, setRequest] = useState();
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index
  const [length, setLength] = useState(0);
  const [formData, setFormData] = useState({});
  const [mission, setMission] = useState("");
  const [query, setQuery] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const getCountsSum = (query) => {
    // 최대 5개의 play_count 값을 찾아 합산
    const playCountMatches = [...query.matchAll(/"play_count": (\d+)/g)];
    const playCountValues = playCountMatches
      .slice(0, 5)
      .map((match) => parseInt(match[1], 10));
    const playCountSum = playCountValues.reduce((acc, count) => acc + count, 0);

    // 최대 5개의 comment_count 값을 찾아 합산
    const commentCountMatches = [...query.matchAll(/"comment_count": (\d+)/g)];
    const commentCountValues = commentCountMatches
      .slice(0, 5)
      .map((match) => parseInt(match[1], 10));
    const commentCountSum = commentCountValues.reduce(
      (acc, count) => acc + count,
      0
    );
    return {
      playCountSum,
      commentCountSum,
    };
  };

  const handleInputChange = (mission, field, value) => {
    const trimmedValue = value.trim(); // 앞뒤 공백 제거
    const parsedValue =
      trimmedValue !== "" && !isNaN(trimmedValue)
        ? Number(trimmedValue)
        : value; // 숫자로 변환 가능하면 숫자로 저장

    setFormData((prev) => ({
      ...prev,
      [mission]: {
        ...prev[mission],
        [field]: parsedValue,
      },
    }));
  };

  const handleVerify = async () => {
    if (mission === "naver") {
      setLoading(true);

      const url = `https://finereview.kro.kr/naver/process_data?phone=${request.phone}&url=${request.naver.url}`;

      const res = await fetch(url, {
        method: "PUT",
      });
      const data = await res.json();
      if (data.status !== "success") {
        alert("승인 실패");
      }
    } else if (mission === "instagram") {
      const payload = {
        [mission]: {
          ...request[mission], // 기존 데이터
          ...formData[mission], // 추가 또는 업데이트할 데이터
        },
        isFineReviewUser: true,
      };
      const res = await updateUser(request._id, payload);
      if (res.msg !== "success") {
        alert("승인 실패");
      }
    }

    const push = await verifyFineReviewUser(request.phone, mission);
    if (push.msg === "success") {
      setLoading(false);
      alert("승인 및 푸시 발송 완료");
      window.location.reload();
    } else {
      alert("푸시 발송 실패");
    }
  };

  const handleReject = async () => {
    alert("거절하시겠어요?");
    const payload = {
      reason,
    };
    const res = await rejectFineReviewUser(request.phone, mission, payload);
    if (res.msg === "success") {
      alert("거절 및 푸시 발송 완료");
      window.location.reload();
    } else {
      alert("푸시 발송 실패");
    }
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + combinedUsers.length) % combinedUsers.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % combinedUsers.length);
  };

  useEffect(() => {
    if (combinedUsers.length === 0) return;
    setRequest(combinedUsers[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    if (request?.instagram) {
      setFormData((prev) => ({
        ...prev,
        instagram: {
          ...prev.instagram,
          post: Number(request.instagram.post) || 0, // NaN 방지
          follower: Number(request.instagram.follower) || 0,
        },
      }));
    } else if (request?.naver) {
      setFormData((prev) => ({
        ...prev,
        naver: {
          ...prev.naver,
          post: Number(request.naver.post) || 0,
          follower: Number(request.naver.follower) || 0,
          comment: Number(request.naver.comment) || 0,
          rank: Number(request.naver.rank) || 0,
        },
      }));
    }
  }, [request]);

  useEffect(() => {
    if (request?.instagram) {
      const { playCountSum, commentCountSum } = getCountsSum(query);
      setFormData((prev) => ({
        ...prev,
        instagram: {
          ...prev.instagram,
          reelView: playCountSum,
          comment: commentCountSum,
        },
      }));
    }
  }, [query]);

  useEffect(() => {
    if (request?.naver) setMission("naver");
    else if (request?.instagram) setMission("instagram");
  }, [request]);

  useEffect(() => {
    const fetchRequest = async () => {
      const res = await getRequestedVerificationUsers();
      console.log(res);
      const combinedUsers = [
        ...(res.users.naver || []),
        ...(res.users.instagram || []),
      ];
      setCombinedUsers(combinedUsers);
      setRequest(combinedUsers[0]); // 첫 번째 사용자만 설정
      setLength(combinedUsers.length);
    };
    fetchRequest();
  }, []);

  return (
    <div>
      <h5>채널 인증 검토</h5>
      {request && (
        <div
          style={{
            width: "fit-content",
            display: "flex",
            backgroundColor: "#FCFDFE",
            padding: "20px",
            gap: "20px",
            alignItems: "center",
          }}
        >
          {loading && (
            <div
              style={{
                zIndex: "100",
                position: "absolute",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                right: "50%",
                transform: "translateX(50%)",
                padding: "100vh",
              }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <IoIosArrowBack fontSize={32} onClick={handlePrevious} />
          <div>
            <h4
              style={{
                textAlign: "right",
              }}
            >
              {currentIndex + 1}/{length}
            </h4>
            <div>
              {request?.naver ? (
                <>
                  <h6
                    style={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <p>
                      {request.naver.nickname} | {request?.phone}
                    </p>
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      gap: "40px",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={request.naver.verificationImage}
                      alt="인증이미지"
                      style={{
                        width: "200px",
                      }}
                    />
                  </div>
                </>
              ) : (
                request?.instagram && (
                  <>
                    <h6
                      style={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <p>
                        {request.instagram.nickname} | {request?.phone}
                      </p>
                      <p>
                        <a
                          href={request.instagram.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          인스타 보기
                        </a>
                      </p>
                    </h6>
                    <div
                      style={{
                        display: "flex",
                        gap: "40px",
                      }}
                    >
                      <img
                        src={request.instagram.verificationImage}
                        alt="인증이미지"
                        style={{
                          width: "200px",
                        }}
                      />
                      <div
                        style={{
                          display: "grid",
                          gap: "10px",
                          maxWidth: "300px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <label style={{ flex: "1", fontWeight: "bold" }}>
                            게시글
                          </label>
                          <input
                            type="text"
                            placeholder="게시글"
                            value={formData["instagram"]?.post || 0}
                            onChange={(e) =>
                              handleInputChange(
                                "instagram",
                                "post",
                                e.target.value
                              )
                            }
                            style={{
                              flex: "2",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label style={{ flex: "1", fontWeight: "bold" }}>
                            팔로워
                          </label>
                          <input
                            type="text"
                            placeholder="팔로워"
                            value={formData["instagram"]?.follower || 0}
                            onChange={(e) =>
                              handleInputChange(
                                "instagram",
                                "follower",
                                e.target.value
                              )
                            }
                            style={{
                              flex: "2",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <label style={{ flex: "1", fontWeight: "bold" }}>
                            쿼리문
                          </label>
                          <input
                            type="text"
                            placeholder="쿼리문"
                            value={query || ""}
                            onChange={(e) => setQuery(e.target.value)}
                            style={{
                              flex: "2",
                              padding: "8px",
                              borderRadius: "4px",
                              border: "1px solid #ddd",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
              <div
                style={{
                  display: "flex",
                  fontSize: "16px",
                  marginTop: "72px",
                  justifyContent: "space-between",
                  gap: "32px",
                }}
              >
                <div
                  onClick={handleVerify}
                  style={{
                    backgroundColor: "#3182F7",
                    color: "white",
                    padding: "10px 20px",
                    width: "fit-content",
                  }}
                >
                  승인
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "20px",
                  }}
                >
                  <input
                    type="text"
                    placeholder="거절 사유"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    style={{
                      padding: "8px",
                      borderRadius: "4px",
                      border: "1px solid #ddd",
                      width: "fit-content",
                    }}
                  />
                  <div
                    onClick={handleReject}
                    style={{
                      backgroundColor: "#FF4D4F",
                      color: "white",
                      padding: "10px 20px",
                      width: "fit-content",
                    }}
                  >
                    거절
                  </div>
                </div>
              </div>
            </div>
          </div>
          <IoIosArrowForward fontSize={32} onClick={handleNext} />
        </div>
      )}
      {!request && (
        <div>인증할 체험단이 없습니다! 고생하셨습니다 윌 & 션 & 마야 😸🙇</div>
      )}
    </div>
  );
}
