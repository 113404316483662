import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import Home from "screens/Home";
import PostList from "screens/PostList";
import Post from "screens/Post";
import RegisterPost from "screens/RegisterPost";
import RegisterPostTag from "screens/RegisterPostTag";
import PostTagList from "screens/PostTagList";
import ConsultReservations from "screens/ConsultReservations";
import Signup from "screens/Signup";
import Login from "screens/Login";
import Admin from "screens/Admin";
import AccommodationSearch from "screens/AccommodationSearch";
import AccommodationRegister from "screens/AccommodationRegister";
import AccommodationCreate from "screens/AccommodationRegister/create";
import AccommodationDetail from "screens/AccommodationRegister/detail";
import { myAxios } from "api/myAxios";
import ChatRoomList from "screens/ChatRoomList";
import ChatRoom from "screens/ChatRoom";
import Package from "screens/Package";
import PackageCreate from "screens/Package/create";
import PackageDetail from "screens/Package/detail";
import PackagePlace from "screens/PackagePlace";
import PackagePlaceCreate from "screens/PackagePlace/create";
import PackagePlaceDetail from "screens/PackagePlace/detail";
import Shorts from "screens/Shorts";
import ShortsDetail from "screens/Shorts/detail";
import ShortsCreate from "screens/Shorts/create";
import CleaningSchedule from "screens/CleaningSchedule";
import CleaningScheduleDetail from "screens/CleaningSchedule/detail";
import SettlementLog from "screens/SettlementLog";
import SettlementLogDetail from "screens/SettlementLog/detail";
import SettlementLogCreate from "screens/SettlementLog/new";
import LodgmentReservationList from "screens/LodgmentReservation";
import OtherPlatformReservationCreate from "screens/OtherPlatformReservation";
import PromotionCreate from "screens/Promotion/create";
import PromotionDetail from "screens/Promotion/detail";
import ConnectedHostAccounts from "screens/ConnectedHostAccounts";
import HauLink from "screens/HauLink";
import HauLinkCreate from "screens/HauLink/create";
import HauLinkDetail from "screens/HauLink/detail";
import PreOpen from "screens/PreOpen";
import PreOpenCreate from "screens/PreOpen/components/create/index";
import SingleDetail from "screens/PreOpen/components/detail/single";
import MultipleDetail from "screens/PreOpen/components/detail/multiple";
import HauData from "screens/HauData";
import HostRegister from "screens/HostRegister";
import Naver from "screens/Naver";
import Settlement from "screens/Settlement";
import Commission from "screens/Commission";
import Push from "screens/Push";
import Notification from "screens/Notification";
import WebsiteDashboard from "screens/Website";
import Review from "screens/Review";
import Creator from "screens/Creator";
import Collaboration from "screens/Review/ManageCollaboration/Collaboration";

const SLink = styled(Link)`
  text-decoration: none;
  color: #ffffff8c;
  white-space: nowrap;
  font-size: 16px;
`;

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  React.useEffect(() => {
    const fetchUser = async () => {
      const jwt = localStorage.getItem("jwt");
      if (!jwt) {
        return;
      }
      await myAxios
        .post({ path: "/adminUsers/check", data: { jwt } })
        .then((res) => {
          setIsLogin(res.data.user.isLogin);
          setIsAdmin(res.data.user.isAdmin);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchUser();
  }, []);

  if (isLogin && isAdmin) {
    return (
      <BrowserRouter>
        <Navbar
          bg="dark"
          variant="dark"
          style={{
            overflowX: "scroll",
          }}
        >
          <Navbar.Brand href="/">
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              Navbar
            </Link>
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link>
              <SLink to="/">Home</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/admin">로그아웃</SLink>
            </Nav.Link>
            {/* <Nav.Link>
        <SLink to="/consultreservations">상담 예약 캘린더</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/post">포스트 목록</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/post/register">포스트 등록</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/postTag">태그 목록</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/postTag/register">태그 등록</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/accommodation/search">숙소 조회</SLink>
      </Nav.Link> 
      <Nav.Link>
        <SLink to="/packagePlace">패키지 장소</SLink>
      </Nav.Link>
      <Nav.Link>
        <SLink to="/package">패키지 만들기</SLink>
      </Nav.Link>
      */}
            <Nav.Link>
              <SLink to="/accommodation/register">숙소</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/shorts">숙소 쇼츠</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/chat">채팅 목록</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/cleaningSchedule">청소 스케줄</SLink>
            </Nav.Link>
            {/* <Nav.Link>
              <SLink to="/settlementLog">정산 내역</SLink>
            </Nav.Link> */}
            <Nav.Link>
              <SLink to="/lodgmentReservation">예약 내역</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/otherPlatformReservationCreate">
                타플랫폼 예약 내역
              </SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/promotion/detail">기업 목록</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/hostConnectedAccounts">호스트 연동 계정</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/naver/connections">네이버 연동</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/haulink">하우링크 목록</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/preopen">pre-open</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/hau-data">HAU Data</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/hostRegister">호스트 등록</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/commission-list-view">수수료 정보</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/settlement">정산</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/push">Push</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/notification">공지 관리</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/websites">웹 빌더</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/review">파인리뷰</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/creator">크리에이터</SLink>
            </Nav.Link>
          </Nav>
        </Navbar>
        <div
          style={{
            paddingTop: "30px",
            paddingLeft: "50px",
            paddingRight: "50px",
            width: "100%",
            overflowX: "scroll",
          }}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/chat" component={ChatRoomList} />
            <Route exact path="/chat/:consultId" component={ChatRoom} />
            <Route
              exact
              path="/consultreservations"
              component={ConsultReservations}
            />
            <Route exact path="/post" component={PostList} />
            <Route exact path="/post/register" component={RegisterPost} />
            <Route exact path="/post/:id" component={Post} />
            <Route exact path="/postTag" component={PostTagList} />
            <Route exact path="/postTag/register" component={RegisterPostTag} />
            <Route
              exact
              path="/accommodation/search"
              component={AccommodationSearch}
            />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/package" component={Package} />
            <Route exact path="/package/new" component={PackageCreate} />
            <Route exact path="/package/:packageId" component={PackageDetail} />
            <Route exact path="/packagePlace" component={PackagePlace} />
            <Route exact path="/naver/connections" component={Naver} />
            <Route
              exact
              path="/packagePlace/:placeId"
              component={PackagePlaceDetail}
            />
            <Route
              exact
              path="/packagePlace/new"
              component={PackagePlaceCreate}
            />
            <Route
              exact
              path="/accommodation/register"
              component={AccommodationRegister}
            />
            <Route
              exact
              path="/accommodation/:accommodationId"
              component={AccommodationDetail}
            />
            <Route
              exact
              path="/accommodation/register/new"
              component={AccommodationCreate}
            />
            <Route exact path="/shorts" component={Shorts} />

            <Route
              exact
              path="/shorts/:shortsPostId"
              component={ShortsDetail}
            />
            <Route exact path="/shorts/new" component={ShortsCreate} />
            <Route
              exact
              path="/cleaningSchedule"
              component={CleaningSchedule}
            />
            <Route
              exact
              path="/cleaningSchedule/:cleaningScheduleId"
              component={CleaningScheduleDetail}
            />
            <Route exact path="/settlementLog" component={SettlementLog} />
            <Route
              exact
              path="/settlementLog/new/:lodgmentId"
              component={SettlementLogCreate}
            />
            <Route
              exact
              path="/settlementLog/:settlementLogId"
              component={SettlementLogDetail}
            />
            <Route
              exact
              path="/lodgmentReservation"
              component={LodgmentReservationList}
            />
            <Route
              exact
              path="/otherPlatformReservationCreate"
              component={OtherPlatformReservationCreate}
            />
            <Route exact path="/promotion/create" component={PromotionCreate} />
            <Route exact path="/promotion/detail" component={PromotionDetail} />
            <Route
              exact
              path="/hostConnectedAccounts"
              component={ConnectedHostAccounts}
            />
            <Route exact path="/haulink" component={HauLink} />
            <Route exact path="/haulink/create" component={HauLinkCreate} />
            <Route exact path="/haulink/detail/:id" component={HauLinkDetail} />
            <Route exact path="/preopen" component={PreOpen} />
            <Route exact path="/preopen/create" component={PreOpenCreate} />
            <Route exact path="/hau-data" component={HauData} />
            <Route
              exact
              path="/preopen/detail/single/:id"
              component={SingleDetail}
            />
            <Route
              exact
              path="/preopen/detail/multiple/:id"
              component={MultipleDetail}
            />
            <Route exact path="/hostRegister" component={HostRegister} />
            <Route exact path="/commission-list-view" component={Commission} />
            <Route exact path="/settlement" component={Settlement} />
            <Route exact path="/push" component={Push} />
            <Route exact path="/notification" component={Notification} />
            <Route exact path="/websites" component={WebsiteDashboard} />
            <Route exact path="/review" component={Review} />
            <Route
              exact
              path="/review/collaboration/:collaborationId"
              component={Collaboration}
            />
            <Route exact path="/creator" component={Creator} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  if (isLogin && !isAdmin)
    return (
      <BrowserRouter>
        <Navbar bg="dark" variant="dark">
          <Navbar.Brand href="/">
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              Navbar
            </Link>
          </Navbar.Brand>
          <Nav className="mr-auto">
            <Nav.Link>
              <SLink to="/">Home</SLink>
            </Nav.Link>
            <Nav.Link>
              <SLink to="/admin">{isLogin ? "로그아웃" : "로그인"}</SLink>
            </Nav.Link>
            {isLogin && (
              <Nav.Link>
                <SLink to="/accommodation/register">숙소</SLink>
              </Nav.Link>
            )}
          </Nav>
        </Navbar>
        <div
          style={{
            paddingTop: "30px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/accommodation/register"
              component={AccommodationRegister}
            />
            <Route
              exact
              path="/accommodation/:accommodationId"
              component={AccommodationDetail}
            />
            <Route
              exact
              path="/accommodation/register/new"
              component={AccommodationCreate}
            />
          </Switch>
        </div>
      </BrowserRouter>
    );

  return (
    <BrowserRouter>
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand href="/">
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            Navbar
          </Link>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link>
            <SLink to="/">Home</SLink>
          </Nav.Link>
          <Nav.Link>
            <SLink to="/admin">{isLogin ? "로그아웃" : "로그인"}</SLink>
          </Nav.Link>
        </Nav>
      </Navbar>
      <div
        style={{
          paddingTop: "30px",
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
